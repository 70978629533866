<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <div>
              <span v-if="modifier_row"
                >{{ modifier_row.Name1 }}-{{ modifier_row.Name2 }}-{{
                  modifier_row.IDn
                }}</span
              >
              <el-button type="primary" @click="add_modifier_item">{{
                $t("Add")
              }}</el-button>
              <el-button
                type="primary"
                class="text-right justify-content-end"
                @click="back_to_list"
                >{{ $t("Back") }}</el-button
              >
            </div>
            <p class="p-3" v-if="modifier_itemlist">
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="modifier_itemlist"
                @row-click="row_clicked_event"
              >
                <el-table-column label="" prop="IDn">
                  <template v-slot="{ row }">
                    {{ row.IDn }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Name') + '1'" prop="Name1">
                </el-table-column>
                <el-table-column :label="$t('Name') + '2'" prop="Name2">
                </el-table-column>
                <el-table-column :label="$t('Price')" prop="Price">
                </el-table-column>
                <el-table-column :label="$t('Sort Order')" prop="SortOrder">
                </el-table-column>
                <el-table-column :label="$t('Action')" v-if="hasUtilityAuth('ProductsDelete')">
                  <template v-slot="{ row }">
                    <el-button type="danger" @click="delele_me(row.IDn)" v-acl:ProductsDelete.enable.show>{{
                      $t("Delete")
                    }}</el-button>
                  </template>
                </el-table-column>
              </el-table>
              {{hasUtilityAuth('ProductsDelete')}}
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col v-if="modifier_row.Type == 'Combo' && cur_opt == 'add'">
          <b-card no-body>
            <div v-if="modifier_row">
              {{ modifier_row.Name1 }}-{{ modifier_row.Name2 }}-{{
                modifier_row.IDn
              }}
            </div>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <p>{{ cur_modifier_item.IDn }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Department')">
                      <select
                        class="form-control"
                        v-model="selected_departmentIDn"
                        @change="get_category_list"
                      >
                        <option
                          v-for="(dpt, idx) in departments"
                          :key="idx"
                          :value="dpt.IDn"
                        >
                          {{ dpt.Name1 }}-{{ dpt.Name2 }}-{{ dpt.IDn }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row v-if="selected_departmentIDn">
                  <b-col>
                    <base-input :label="$t('Category')">
                      <select
                        class="form-control"
                        v-model="selected_categoryIDn"
                        @change="get_product_list"
                      >
                        <option
                          v-for="(cat, idx) in categorys"
                          :key="idx"
                          :value="cat.IDn"
                        >
                          {{ cat.Name1 }}-{{ cat.Name2 }}-{{ cat.IDn }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row v-if="selected_categoryIDn">
                  <b-col>
                    <base-input :label="$t('Product')">
                      <select
                        class="form-control"
                        v-model="selected_productIDn"
                        multiple
                      >
                        <option
                          v-for="(prod, idx) in products"
                          :key="idx"
                          :value="prod.IDn"
                        >
                          {{ prod.Name1 }}-{{ prod.Name2 }}-{{ prod.IDn }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>

                <b-row class="mt-3">
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col v-else>
          <b-card no-body>
            <div v-if="modifier_row">
              {{ modifier_row.Name1 }}-{{ modifier_row.Name2 }}-{{
                modifier_row.IDn
              }}
            </div>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <p>{{ cur_modifier_item.IDn }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Name') + '1'">
                      <b-input
                        placeholder="Name1"
                        class="form-control"
                        v-model="cur_modifier_item.Name1"
                        required
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Name') + '2'">
                      <b-input
                        placeholder="Name2"
                        class="form-control"
                        v-model="cur_modifier_item.Name2"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Price')">
                      <b-input
                        placeholder="Price"
                        class="form-control"
                        v-model="cur_modifier_item.Price"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Sort Order')">
                      <b-input
                        type="number"
                        min="1"
                        max="999"
                        placeholder="Sort Order"
                        class="form-control"
                        v-model="cur_modifier_item.SortOrder"
                        required
                      />
                    </base-input>
                  </b-col>
                </b-row>

                <b-row class="mt-3">
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button, Alert } from "element-ui";
import { getStore, setStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  data() {
    return {
      currpage: "list",
      cur_ModifierGroupIDn: 0,
      modifier_row: null,
      cur_modifier_item: null,
      cur_opt: null,
      new_modifier_item: {
        IDn: "",
        ID: "",
        Name1: "",
        Name2: "",
        ModifierGroupID: "",
        ModifierGroupIDn: "",
        ProductIDn: "",
        Price: "",
        SortOrder: "999",
        Status: "1",
      },
      modifier_itemlist: null,
      departments: null,
      selected_departmentIDn: 0,
      categorys: null,
      selected_categoryIDn: 0,
      products: null,
      selected_productIDn: [],

      token: "",
      user_id: 0,
    };
  },
  methods: {
    hasUtilityAuth,
    add_modifier_item() {
      this.currpage = "edit";
      this.cur_modifier_item = this.new_modifier_item;
      (this.cur_modifier_item.ModifierGroupIDn = this.cur_ModifierGroupIDn),
        (this.cur_opt = "add");
    },
    row_clicked_event(row, event, column) {
      //console.log("row_clicked_event", row, event, column);
      if (column.label == "Action") {
        return;
      }
      this.currpage = "edit";
      this.cur_modifier_item = JSON.parse(JSON.stringify(row));
      this.cur_opt = "update";
    },
    form_cancel() {
      this.currpage = "list";
    },
    back_to_list() {
      this.$router.push("/modifier");
    },
    async delele_me(idn, event) {
      for (var row of this.modifier_itemlist) {
        if (row.IDn == idn) {
          this.cur_modifier_item = row;
          this.cur_opt = "disable";
          return this.form_submit(event);
        }
      }
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_modifier_item) {
        if (this.cur_modifier_item[key])
          bodyFormData.append(key, this.cur_modifier_item[key]);
      }
      bodyFormData.append("SDepartmentIDn", this.selected_departmentIDn);
      bodyFormData.append("SCategoryIDn", this.selected_categoryIDn);
      bodyFormData.append("SProductIDn", this.selected_productIDn);

      axios({
        method: "post",
        url: "/Api/Web/modifier_item/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
              that.$router.go();
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_category_list() {
      this.selected_categoryIDn = 0;
      if (this.selected_departmentIDn == 0) {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("DepartmentIDn", this.selected_departmentIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Category/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.categorys = rt.data.categorys;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_product_list() {
      this.selected_productIDn = [];
      if (this.selected_categoryIDn == 0) {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("CategoryIDn", this.selected_categoryIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Product/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.products = rt.data.products;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_modifier_item_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("ModifierGroupIDn", this.cur_ModifierGroupIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/modifier_item",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.modifier_row = rt.data.modifier;
              that.modifier_itemlist = rt.data.modifier_items;
              that.departments = rt.data.departments;
              that.selected_departmentIDn = 0;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      this.cur_ModifierGroupIDn = this.$route.query.idn;
      return this.get_modifier_item_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
